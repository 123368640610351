import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ProjectList from '../components/Posts/ProjectList'
import { PageHeader } from '../components/PageHeader'
import { decodeEntities } from '../utils/helpers'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const {
      postsOnPage,
      categories,
      thisCategory,
      site,
      yoast = [],
      wordpressWpSettings,
      siteSettings,
    } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { acf, description } = thisCategory
    const { name, slug, pathPrefix } = pageContext
    const title = acf && acf.title ? acf.title : name
    const pageDescription = acf.categoryPageDescription
      ? acf.categoryPageDescription
      : description
    const { newsletterText } = siteSettings.options

    return (
      <>
        <div className={`page-wrap`}>
          <SEO
            title={`${decodeEntities(name)} | ${decodeEntities(siteTitle)}`}
            yoast={yoast}
            location={location}
          />
          <PageHeader
            headerTitle={title}
            headerSubTitle={description}
            location={location}
          />
          <ProjectList
            posts={posts}
            title={title}
            pageContext={pageContext}
            categories={categories.edges}
            siteMetadata={wordpressWpSettings}
            pathPrefix={pathPrefix}
          />
        </div>
        <NewsletterSignUp content={newsletterText} />
      </>
    )
  }
}

export const pageQuery = graphql`
  query ProjectCategoryPage($category_id: [Int], $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    postsOnPage: allWordpressWpProjects(
      filter: { project_category: { in: $category_id } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    thisCategory: wordpressWpProjectCategory(
      wordpress_id: { in: $category_id }
    ) {
      description
      acf {
        title
        categoryPageDescription
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
